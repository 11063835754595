@import '../../../resources/colors.module.scss';

.closeContainer {
  position: fixed;
  top: 0;
  right: 6vmin;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 2vmin;
  border-bottom-left-radius: 2vmin;
  height: 2vmin;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  text-align: center;
  padding: 6.5vmin 2vmin;
  background-color: $whiteBackground;
  .iconClose {
    font-size: 8vmin;
  }
}

@media (min-width: 650px) {
  .closeContainer {
    padding: 3.5vmin 2vmin;
    right: 10vw;
    .iconClose {
      font-size: 4vmin;
    }
  }
}
