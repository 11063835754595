@import '../../../resources/colors.module.scss';

.eTrainingContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  @media (max-width: 768px) {
    position: relative;
    margin-bottom: 20px;
  }
  @media (min-width: 1500px) {
    position: absolute;
    bottom: 0;
  }
}

.greyContainer {
  width: 100%;
  background-color: #262626;
  // height: 100%;
  margin-top: -1px;
  // box-shadow: inset 0 10px 10px 0px #00000078;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 30px 50px;

  // @media (min-width: 320px) {
  //   // height: 30vh;
  //   // overflow-y:auto;
  //   font-size: 14px;
  // }
  // @media (min-width: 640px) {
  //   height: 30vh;
  //   overflow-y:auto;
  // }
  @media (min-width: 768px) {
    height: 20vh;
    overflow-y:auto;
  }
  @media (min-width: 1024px) {
    height: 15vh;
    overflow-y:auto;
  }
  @media (min-width: 1366px) {
    height: 19vh;
    overflow-y:auto;
  }
  @media (min-width: 1920px) {
    height: 27vh;
    overflow-y: auto;
  }

  // @media (max-width: 1500px) {
  //   padding: 15px 50px;
  // }
  @media (max-width: 640px) {
    padding: 15px;
    font-size: 14px;
  }
}
.listContainer {
  max-width: 1110px;
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-template-columns: 1fr 1fr;
    /* This is better for small screens, once min() is better supported */
    /* grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr)); */
    gap: 1rem;
    // padding: 15px 50px;
  }
}

.menuLinkWrapper {
  width: 33%;
  padding: 5px 0;
  // @media (min-width: 1366px) {
  //   padding-top: 2%;
  // }
  @media (max-width: 768px) {
    width: 100%;
    padding: 5px 0;
  }
  @media (max-width: 1365px) {
    padding: 2px 0;
  }
}

.menuLinks {
  color: white;
  font-size: 26px;
  text-decoration: none;
  @media (max-width: 640px) {
    font-size: 20px;
  }
}

.menuLinks:hover {
  color: #d0d0d0;
}

.arrowup {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;

  border-bottom: 20px solid #262626;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  width: 100%;
  @media (max-width: 768px) {
    min-height: auto;
  }
  .buttonsContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;

    .innerButtonsContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      max-width: 1110px;
      width: 100%;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      @media (max-width: 400px) {
        // height: 80px; WIP
      }

      .arrowContainer {
        width: 33.33%;
        display: flex;
        justify-content: center;
        @media (max-width: 1365px) {
          width: 100%;
        }
      }
    }
  }
}
