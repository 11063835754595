@import '../../../../../resources/colors.module.scss';

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1110px;
  margin-bottom: 5vh;
  color: $black;
  font-size: 34px;
  font-weight: bold;

  @media(max-width: 960px) {
    font-size: 30px;
  }
  @media(max-width: 600px) {
    font-size: 24px;
  }
  @media(max-width: 400px) {
    font-size: 20px;
  }


  .title {
    margin-top: 2.5vh;
  }
}

.closeContainer {
  display: flex;
  justify-content: flex-end;
  padding: auto;

  .buttonsContainer {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: $whiteBackground;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    z-index: 1;
    cursor: pointer;
  
    @media (max-width: 750px) {
      padding: 10px;
    }
    
    .iconClose {
      font-size: 36px;
  
      @media(max-width: 960px) {
        font-size: 34px;
      }
      @media(max-width: 600px) {
        font-size: 30px;
      }
      @media(max-width: 400px) {
        font-size: 28px;
      }
    }
  }
}
