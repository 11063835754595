@import '../../../resources/colors.module.scss';

.container {
  display: flex;
  flex-direction: column;
  background-color: $white;
  height: 100%;

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-y: scroll;
    overflow-x: hidden;

    .separator {
      background-color: $darkBlack;
      width: 90%;
      height: 2px;
      margin-top: 50px;
    }

    .titleContainer {
      // display: flex;
      // flex-direction: column;
      align-items: center;
      padding: 0 20px;
      width: 100%;
      max-width: 1150px;

      @media (max-width: 1180px) {
        padding: 0 40px;
      }
      @media (max-width: 400px) {
        padding: 0 20px;
      }

      .title {
        width: 100%;
        text-transform: uppercase;
        font-weight: bold;
        margin-top: 5vh;
      }

      .subtitle {
        width: 100%;
        margin-top: 1.5vh;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .imageContainer {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .closeContainer {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        max-width: 1110px;
        margin: auto;
        padding: auto;

        .buttonsContainer {
          position: fixed;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          background-color: $whiteBackground;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
            rgba(0, 0, 0, 0.23) 0px 3px 6px;
          z-index: 1;
          cursor: pointer;

          @media (max-width: 1180px) {
            margin: 0 40px;
          }
          @media (max-width: 400px) {
            margin: 0 20px;
          }

          .iconClose {
            font-size: 36px;

            @media (max-width: 960px) {
              font-size: 34px;
            }
            @media (max-width: 600px) {
              font-size: 30px;
            }
            @media (max-width: 400px) {
              font-size: 28px;
            }
          }
        }
      }
    }

    .description {
      margin: 0 auto;
      margin-top: 3vmin;
      width: 50vw;
      color: #393939;
      font-size: 26px;
      line-height: 36px;

      @media (max-width: 1280px) {
        width: auto;
      }

      @media (max-width: 768px) {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}
