@import '../../../../../resources/colors.module.scss';

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cup {
  width: auto !important;
  height: 230px !important;
  fill: $white !important;

  @media (max-width: 768px) {
    height: 180px !important;
  }

  @media (max-width: 480px) {
    height: 130px !important;
  }
}

.counter {
  position: absolute;
  padding-bottom: 70px;
  color: $primary;
  font-size: 72px;
  font-weight: 900;

  @media (max-width: 768px) {
    padding-bottom: 52px;
    font-size: 64px;
  }

  @media (max-width: 480px) {
    padding-bottom: 42px;
    font-size: 52px;
  }
}
