@import '../../../resources/colors.module.scss';

.container {
  position: fixed;
  top: 150px;
  right: 0;
  z-index: 999;

  @media (max-width: 1280px) {
    display: none;
  }
}
.appIcon {
  padding: 10px;
  width: auto;
  height: 100%;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  width: 75px;
  height: 57px;
  border-radius: 4px 0 0 4px;
  background-color: $white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 6px;
  transition: background-color 0.2s;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    background-color: $primary !important;

    .icon {
      fill: $white !important;
    }
  }
}

.icon {
  height: 36px !important;
  width: fit-content !important;
  fill: #383838 !important;
}

.appLogo {
  height: 70px !important;
}

.appStoreIcon {
  margin-bottom: 2px !important;
  height: 36px !important;
  width: fit-content !important;
  fill: #000 !important;
}

.playStoreIcon {
  margin-bottom: 2px !important;
  height: 34px !important;
  width: fit-content !important;
}

.gap {
  height: 50px;
}

.counter {
  position: absolute;
  top: 6px;
  right: 14px;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border: solid 2px white;
  border-radius: 11px;
  background-color: $primary;
}

.counterText {
  color: $white;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
