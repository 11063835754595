@font-face {
  font-family: "TeleNeoWeb";
    src: local("TeleNeo Web"), local("TeleNeoWeb-Regular"), url("../resources/fonts/TeleNeoWeb-Regular.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "TeleNeoWeb";
    src: local("TeleNeo Web Regular Italic"), local("TeleNeoWeb-RegularItalic"), url("../resources/fonts/TeleNeoWeb-RegularItalic.woff") format("woff");
  font-style: italic;
}
@font-face {
  font-family: "TeleNeoWeb";
    src: local("TeleNeo Web Bold"), local("TeleNeoWeb-Bold"), url("../resources/fonts/TeleNeoWeb-Bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "TeleNeoWeb";
    src: local("TeleNeo Web Bold Italic"), local("TeleNeoWeb-BoldItalic"), url("../resources/fonts/TeleNeoWeb-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "TeleNeoWeb";
    src: local("TeleNeo Web Thin"), local("TeleNeoWeb-Thin"), url("../resources/fonts/TeleNeoWeb-Thin.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "TeleNeoWeb";
    src: local("TeleNeo Web Medium"), local("TeleNeoWeb-Medium"), url("../resources/fonts/TeleNeoWeb-Medium.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "TeleNeoWeb";
    src: local("TeleNeo Web Medium Italic"), local("TeleNeoWeb-MediumItalic"), url("../resources/fonts/TeleNeoWeb-MediumItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "TeleNeoWeb";
    src: local("TeleNeo Web Extra Bold"), local("TeleNeoWeb-ExtraBold"), url("../resources/fonts/TeleNeoWeb-ExtraBold.woff") format("woff");
  font-weight: 900;
}

html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "TeleNeoWeb";
}

#root {
  width: 100%;
  height: 100%;
}