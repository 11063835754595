@import '../../../../../../resources/colors.module.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 10px;
  margin-left: 5px;
  cursor: pointer;
}

// HOT FIX
.icon {
  width: 25px !important;
  height: 25px !important;
  fill: $black !important;

  &:hover {
    fill: $primary !important;
  }
}
