@import '../../../../../../../resources/colors.module.scss';

.container {
  display: flex;
  width: 100%;
  flex-direction: row;
  // margin-top: auto;
}

.button {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 41px;
  width: 256px;
  border-radius: 4px;
  border: solid 1px #b2b2b2;
  cursor: pointer;
}

.uploadButton {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 41px;
  width: 256px;
  border-radius: 4px;
  border: solid 1px $primary;
  background-color: $primary;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.buttonsGap {
  width: 20px;
}

.buttonText {
  color: $white;
  font-size: 18px;
  font-family: 'TeleNeoWeb';
  font-weight: 700;
  text-transform: uppercase;
}

.disabledText {
  opacity: 0.8;
}

.disabledButton {
  background-color: #b2b2b2;
  border-color: #b2b2b2;
}
