@import '../../../resources/colors.module.scss';

// $slide1-bg: url('../../../resources/png/bg_horizontal.jpg');


.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-height: 1020px;
  width: 100%;
  // background-image: $slide1-bg;
  // background-size: cover;
  // background-position: center;
  // background-repeat: no-repeat;
  overflow: hidden;

  @media (max-width: 1190px) {
    align-items: unset;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 40px;
    max-width: 1110px;

    @media (max-width: 400px) {
      margin: 0 20px;
    }

    .buttonWrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 150px;
      width: 100vw;
      background-color: rgba(0, 0, 0, 0.5);

      @media (max-width: 480px) {
        height: 88px;
      }

      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 40px;
        height: 62px;
        max-width: 540px;
        width: 100%;
        font-size: 26px;
        font-weight: bold;
        color: $primary;
        text-transform: uppercase;
        border: solid 1px rgba(0, 0, 0, 0.4);
        border-radius: 4px;
        background: $white;
        transition: all 0.2s;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }

        @media (max-width: 480px) {
          height: 48px;
          font-size: 20px;
        }

        @media (max-width: 400px) {
          margin: 0 20px;
        }
      }
    }
  }
}
