@import '../../../../../../../resources/colors.module.scss';

.container {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background-color: $white;
  border-radius: 15px;
}

.innerCheckbox {
  height: 16px;
  width: 16px;
  border-radius: 8px;
  overflow: hidden;
}

.active {
  background-color: $primary;
}

.buttonsGap {
  height: 20px;
}

.buttonText {
  display: flex;
  flex: 1;
  margin-left: 20px;
  color: $white;
  font-family: 'TeleNeoWeb';
  font-weight: 600;
  font-size: 18px;
}
