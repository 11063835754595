@import '../../../resources/colors.module.scss';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;
  overflow: hidden;

  @media (max-width: 1190px) {
    align-items: unset;
  }
  .darkBackground {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .title {
    margin-top: 50px;
    width: 100%;
    color: $white;
    font-weight: 900;
    font-size: 32px;
    text-align: center;

    @media (max-width: 768px) {
      margin-top: 30px;
      font-size: 26px;
    }

    @media (max-width: 480px) {
      margin-top: 30px;
      font-size: 22px;
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    margin: auto 0;

    .innerContent {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
      margin: 0 40px;
      width: 100%;
      max-width: 1110px;

      // @media (max-width: 1280px) {
      //   flex-direction: column;
      //   margin: 0 40px;
      // }

      @media (max-width: 768px) {
        margin: 0;
        padding: 0 40px;
      }

      @media (max-width: 400px) {
        padding: 0 20px;
      }
    }
  }
}

.gauge {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 600px;
  height: 600px;

  @media (max-width: 768px) {
    width: 500px;
    height: 500px;
  }

  @media (max-width: 500px) {
    width: 380px;
    height: 380px;
  }

  @media (max-width: 400px) {
    width: 320px;
    height: 320px;
  }
}

.gaugeBackground {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: 70%;
}

.chartInnerView {
  position: absolute;
  width: 370px;
  height: 370px;
  border-radius: 50%;
  justify-content: center;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 305px;
    height: 305px;
  }

  @media (max-width: 500px) {
    width: 230px;
    height: 230px;
  }

  @media (max-width: 400px) {
    width: 190px;
    height: 190px;
  }
}

.cupCross {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 1000;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.4);
}

.cupCrossImg {
  width: 160px;
  height: 160px;
}

.cupCrossTex {
  margin: 0 10px;
  margin-top: 5px;
  color: $darkBlack;
  font-size: 3vmin;
  text-align: center;
  font-weight: bold;
  color: $primary;
}

.cup {
  width: 100%;
  height: 100%;
  align-self: center;
  justify-content: center;
  position: 'relative';
  background-color: $white;
}

.cupImage {
  width: 90%;
  align-self: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cupText {
  margin: 0 10px;
  margin-top: 5px;
  width: 80%;
  color: $darkBlack;
  font-size: 28px;
  text-align: center;
  font-weight: bold;
  color: $white;

  @media (max-width: 500px) {
    font-size: 24px;
  }

  @media (max-width: 400px) {
    font-size: 20px;
  }
}

.questionMark {
  font-size: 10vmin;
  font-weight: bold;
  color: $primary;
}

.subContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.labelBalance {
  color: $white;
  font-size: 50px;
  line-height: 50px;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 42px;
  }
}

.labelText {
  margin-top: 0px;
  color: $white;
  font-size: 26px;
  line-height: 26px;
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: 22px;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  margin: 0 40px;
  margin-top: 50px;
  margin-bottom: 20px;
  max-width: 1110px;
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 30px;
    flex-direction: column;
  }

  @media (max-width: 400px) {
    margin: 0 20px;
    margin-top: 20px;
  }

  .gap {
    width: 30px;
    height: 10px;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 62px;
    font-size: 26px;
    font-weight: bold;
    color: $primary;
    text-transform: uppercase;
    border: solid 1px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    background: $white;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    @media (max-width: 960px) {
      font-size: 22px;
    }

    @media (max-width: 768px) {
      font-size: 20px;
      flex: unset;
    }

    @media (max-width: 480px) {
      height: 48px;
      font-size: 20px;
    }
  }
}
