@import '../../../../../../resources/colors.module.scss';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  max-width: 540px;

  @media (max-width: 960px) {
    margin-top: 6px;
  }
}

.inputContainer {
  width: 100%;
}

.inputContainerGap {
  margin-bottom: 10px;
}

.label {
  color: $whiteBackground;
  font-family: 'TeleNeoWeb';
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  cursor: default;
}

.input {
  padding: 0 10px;
  width: 100%;
  height: 34px;
  color: #757677;
  font-size: 18px;
  font-family: 'TeleNeoWeb';
  font-weight: 600;
  border-radius: 4px;
  border: solid 1px #b2b2b2;
  background-color: white;
  outline: none;

  &:focus {
    color: #343435;
    border: solid 1px #65afec;
  }
}

.textField {
  padding: 6px 10px;
  height: 80px;
  resize: none;
}
