@import '../../../../resources/colors.module.scss';

.container {
  width: 100%;
}

.modal {
  position: fixed;
  bottom: 90px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: bold;
  background-color: $whiteGray;
  transition: 0.5s;
  z-index: 999;

  @media (max-width: 1080px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 40px;
  }

  @media (max-width: 400px) {
    padding: 0 20px;
  }

  .subMenuItemText {
    padding: 42px 26px;
    text-transform: uppercase;
    font-size: 24px;
    color: $darkBlack;
    transition: color 0.1s;
    cursor: pointer;

    &:hover {
      color: $primary;
    }

    @media (max-width: 1080px) {
      width: 100%;
    }
  }

  .separator {
    height: 62px;
    width: 2px;
    background-color: $gray;

    @media (max-width: 1080px) {
      height: 2px;
      width: 100%;
    }
  }
}

.footer {
  position: relative;
  display: flex;
  justify-content: center;
  height: 90px;
  width: 100%;
  background-color: $white;
  box-shadow: 0 4px 10px 8px rgba(0, 0, 0, 0.1);
  z-index: 999;

  .innerFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 115px;
    max-width: 930px;
    height: 100%;
    width: 100%;

    @media (max-width: 768px) {
      margin: 0 100px;
    }
  }
}

.menuLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 14px;
  height: 100%;
  text-decoration: none;
  cursor: pointer;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  &:hover {
    .icon {
      fill: $primary;
    }
  }

  .icon {
    height: 44px;
    fill: #505050;
    width: auto;
    cursor: pointer;
  }

  .active {
    fill: $primary;
  }
}
