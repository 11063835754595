$black: #000000;
$white: #ffffff;
$primary: #cb0069;
$darkGray: #262626;
$lightGray: #a49faf;
$darkGrayText: #38393a;
$lightGrayText: #373839;
$boarderGray: #c4c5c5;
$darkBlack: #393939;
$whiteBackground: #ededed;
$switcherGreen: #47a801;
$switcherGrayBoarder: #acacac;
$switcherGreenBoarder: #65a134;
$whiteGray: #ddd;
$lightPrimary: #e20074;
$gold: #c5a255;
$gray: #888;
