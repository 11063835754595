@import '../../../../resources/colors.module.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 400px;

  @media (max-width: 400px) {
    height: auto;
  }

  .cup {
    width: auto;
    height: 270px;
    fill: #c9a256;

    @media (max-width: 400px) {
      height: 230px;
    }
  }

  .counter {
    position: absolute;
    padding-bottom: 88px;
    color: $white;
    font-size: 80px;
    font-weight: 900;

    @media (max-width: 400px) {
      padding-bottom: 74px;
      font-size: 70px;
    }
  }
}
