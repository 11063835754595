@import '../../../resources/colors.module.scss';

.container {
  position: fixed;
  top: 50%;
  left: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(-50%);
  z-index: 3;

  @media (max-width: 1280px) {
    display: none;
  }
}

.largeCircle {
  margin: 6px 0;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border-color: $white;
  border-width: 3px;
  border-style: solid;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2),
    inset 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
  transition: all 0.2s;
  cursor: pointer;
}

.active {
  margin: 6px 0;
  width: 26px;
  height: 26px;
  border-radius: 14px;
  border-color: $primary;
  border-width: 6px;
}

.smallCircle {
  margin: 3px 0;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: $white;
  opacity: 0.4;
}
