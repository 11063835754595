@import '../../../../resources/colors.module.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .title {
    margin-top: 50px;
    color: $white;
    font-weight: 900;
    font-size: 32px;

    @media (max-width: 768px) {
      margin-top: 30px;
      font-size: 26px;
    }

    @media (max-width: 480px) {
      margin-top: 30px;
      font-size: 22px;
    }
  }

  .description {
    margin-top: 30px;
    margin-bottom: 26px;
    color: $white;
    font-size: 26px;
    line-height: 34px;

    @media (max-width: 600px) {
      margin-bottom: 0px;
      font-size: 24px;
      line-height: 32px;
    }

    @media (max-width: 480px) {
      margin-top: 15px;
      font-size: 22px;
      line-height: 28px;
    }
  }
}
