@import '../../../../../../resources/colors.module.scss';

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  height: 100%;
  border: solid #98999a 1px;
  border-right-color: transparent;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #373839;
  cursor: pointer;
  z-index: 1;

  @media (max-width: 1000px) {
    width: 130px;
  }
}

.label {
  display: flex;
  flex: 1;
  margin-left: 10px;
  font-size: 18px;
  font-family: 'TeleNeoWeb';
  font-weight: 600;
  color: $white;

  @media (max-width: 400px) {
    font-size: 16px;
  }
}

// HOT FIX
.arrowIcon {
  margin-right: 14px !important;
  width: 14px !important;
  height: 14px !important;
  fill: $whiteBackground !important;
}

.modalArea {
  display: flex;
  flex: 1;
}

.optionsContainer {
  position: absolute;
  top: 46px;
  width: 100%;
  border-radius: 4px;
  background-color: $white;
  overflow: hidden;
  z-index: 1;
}

.optionItem {
  padding: 10px;
  cursor: pointer;
}

.selectedOption {
  background-color: #d8d9da;
}

.selectItemText {
  font-size: 18px;
  font-family: 'TeleNeoWeb';
  font-weight: 400;

  @media (max-width: 400px) {
    font-size: 16px;
  }
}
