@import '../../../resources/colors.module.scss';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;

  @media (max-width: 1024px) {
    min-height: 100%;
    height: auto;
  }

  .title {
    margin-top: 50px;
    color: $white;
    font-weight: 900;
    font-size: 32px;

    @media (max-width: 768px) {
      margin-top: 30px;
      font-size: 26px;
    }

    @media (max-width: 480px) {
      margin-top: 30px;
      font-size: 22px;
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    margin: auto 0;

    .innerContent {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-grow: 1;
      margin: 0 40px;
      width: 100%;
      max-width: 1110px;

      @media (max-width: 1024px) {
        flex-direction: column;
        margin: 0 40px;
      }

      @media (max-width: 768px) {
        margin: 0;
        padding: 0 40px;
      }

      @media (max-width: 400px) {
        padding: 0 20px;
      }
    }
  }
}
