@import '../../../../resources/colors.module.scss';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px 20px;
  margin: 15px;
  width: 350px;
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}
