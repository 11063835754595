@import '../../../../resources/colors.module.scss';

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cup {
  width: auto !important;
  height: 50px !important;
  fill: '#A4A4A4';

  // @media (max-width: 768px) {
  //   height: 180px;
  // }

  // @media (max-width: 480px) {
  //   height: 130px;
  // }
}

.gold {
  fill: #C9A256;
}

.counter {
  position: absolute;
  padding-bottom: 16px;
  color: $white;
  font-size: 24px;
  font-weight: 900;

  // @media (max-width: 768px) {
  //   padding-bottom: 52px;
  //   font-size: 64px;
  // }

  // @media (max-width: 480px) {
  //   padding-bottom: 42px;
  //   font-size: 52px;
  // }
}
