@import '../../../resources/colors.module.scss';

.cupContainer {
  display: flex;
  width: 16vmin;
  height: 16vmin;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .iconCup {
    display: flex;
    width: 16vmin;
    height: 16vmin;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    fill: white;
  }

  .labelCup {
    font-weight: bold;
    font-size: 5vmin;
    text-transform: uppercase;
    margin-top: -20vmin;
    color: $primary;
  }

  .firstRankingCup {
    width: 30vmin;
    height: 30vmin;
    fill: $gold;
  }

  .firstRankingCup + .labelCup {
    margin-top: -40vmin;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 9vmin;
    color: $white;
  }
}

.lastRankingPosContainer {
  width: 30vmin;
  height: 30vmin;

  .lastRankingPos {
    width: 30vmin;
    height: 30vmin;
  }

  .lastRankingPos + .labelCup {
    font-weight: bold;
    font-size: 7vmin;
    text-transform: uppercase;
    margin-top: -35vmin;
    color: $primary;
  }
}

.prevRankingPosContainer {
  width: 20vmin;
  height: 20vmin;

  .prevRankingPos {
    width: 20vmin;
    height: 20vmin;
  }

  .prevRankingPos  + .labelCup {
    margin-top: -25vmin;
  }
}

@media (max-width: 650px) {

  .monthRankingPosContainer {
    width: 30vw;
    height: 30vw;

    .monthRankingPos {
      width: 30vw;
      height: 30vw;
    }

    .monthRankingPos + .labelCup {
      font-size: 10vw;
      margin-top: -40vw;
    }

  }

  .cupContainer {
    .labelCup {
      font-size: 7vmin;
    }

    .lastRankingPos {
      width: 30vmin;
      height: 30vmin;
      color: white;
    }

    .lastRankingPos + .labelCup {
      font-size: 10vmin;
      margin-top: -40vmin;
    }
  }
}
