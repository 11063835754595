@import '../../../../../resources/colors.module.scss';

.button {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 350px;
  flex-basis: 350px;
  background-color: $primary;
  border-radius: 4px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 800px) {
    max-width: 100%;
    flex-basis: auto;

    &:hover {
      opacity: 1;
    }
  }
}

.buttonIcon {
  position: absolute !important;
  left: 10px !important;
  width: 32px !important;
  height: 32px !important;
  fill: $whiteBackground !important;
}

.buttonText {
  padding: 8px 0;
  height: 100%;
  width: 100%;
  color: $whiteBackground;
  text-transform: uppercase;
  font-size: 18px;
  font-family: 'TeleNeoWeb';
  font-weight: 700;
  text-align: center;
  cursor: pointer;
}
