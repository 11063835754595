@import '../../../resources/colors.module.scss';

.container {
  margin-top: 2vmin;
  background-color: $whiteBackground;
  padding: 8vmin 4vmin;

  .description {
    font-size: 5vmin;
    line-height: 6.5vmin;
  }

  .buttonsContainer {
    margin-top: 8vmin;
    .button {
      width: 44vmin;
      height: 30vmin;
      padding: 3vmin;
      margin-bottom: 5vmin;
      background-color: $white;
      cursor: pointer;
      border-radius: 1vmin;
      display: inline-block;
      box-shadow: -1px 3px 6px rgba(0, 0, 0, 0.16),
        3px 3px 6px rgba(0, 0, 0, 0.33);

      .icon {
        font-size: 4vmin;
        color: $lightGray;
      }
      .iconSelected {
        color: $white;
      }

      .text {
        display: grid;
        font-size: 4.5vmin;
        text-align: center;
        padding-top: 4vmin;
        color: $darkGrayText;
      }

      .textSelected {
        color: $white;
      }
    }

    .buttonSelected {
      background-color: $primary;
    }
  }

  .buttonNext {
    padding: 3vmin;
    margin-top: 3vmin;
    background-color: $primary;
    cursor: pointer;
    border-radius: 1vmin;
    .buttonNextText {
      color: $white;
      font-size: 4.5vmin;
      text-align: center;
      font-weight: bold;
    }
  }

  .progress {
    margin-top: 15vmin;
    border-radius: 1vmin;
  }
}

@media (min-width: 650px) {
  .container {
    padding: 4vmin 2vmin;
    .content {
      max-width: 800px;
      margin: 0 auto;
      .description {
        font-size: 3vmin;
        line-height: 4vmin;
      }
      .buttonsContainer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 2vmin;
        grid-row-gap: 2vmin;
        grid-auto-rows: 1fr;
        margin-top: 4vmin;
        .button {
          width: auto;
          height: auto;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          padding: 1.5vmin;
          margin: 0 !important;
          border-radius: 0.5vmin;

          .icon {
            font-size: 2.75vmin;
          }
          .text {
            padding: 0;
            font-size: 3vmin;
            flex-grow: 1;
          }
        }
      }
      .buttonNext {
        margin: 0 auto;
        max-width: 500px;
        padding: 1.5vmin;
        margin-top: 3vmin;
        border-radius: 0.5vmin;
        .buttonNextText {
          font-size: 3.5vmin;
        }
      }
      .progress {
        margin-top: 5vmin;
      }
    }
  }
}
