@import '../../../../../../resources/colors.module.scss';

.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 2;
}

.title {
  margin-top: 10px;
  margin-bottom: 5px;
  color: $primary;
  font-size: 18px;
  font-family: 'TeleNeoWeb';
  font-weight: 700;
  cursor: default;
}

.inputContainer {
  width: 100%;
}

.inputContainerGap {
  margin-bottom: 10px;
}

.label {
  color: $darkGray;
  font-family: 'TeleNeoWeb';
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  cursor: default;
}

.inputs {
  margin: auto 0;
  width: 100%;
}

.input {
  padding: 0 10px;
  width: 100%;
  height: 34px;
  color: #757677;
  font-size: 18px;
  font-family: 'TeleNeoWeb';
  font-weight: 600;
  border-radius: 4px;
  border: solid 1px #b2b2b2;
  background-color: white;
  outline: none;

  &:focus {
    color: #343435;
    border: solid 1px #65afec;
  }
}

.textField {
  padding: 6px 10px;
  height: 80px;
  resize: none;
}

.buttons {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: auto;
}

.button {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 41px;
  border-radius: 4px;
  border: solid 1px #b2b2b2;
  background-color: $whiteBackground;
  cursor: pointer;
}

.buttonsGap {
  width: 20px;
}

.buttonText {
  color: #383838;
  font-size: 18px;
  font-family: 'TeleNeoWeb';
  font-weight: 700;
  text-transform: uppercase;
}

.disabled {
  color: #b2b2b2;
}
