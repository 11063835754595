body {
  background-color: #e20074;
  background-image: url('../resources/png/bg_horizontal_2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 768px) {
  body {
    background-image: url('../resources/png/bg_vertikal_2.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.App {
  text-align: center;
  height: 80vmin;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.labelCheck {
  color: #52b9f1;
  font-family: TeleNeoWeb;
  font-size: 3vmin;
}
.combo-outline {
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  border-top-width: 0 !important;
  border-radius: 0 !important;
}
.select-container {
  padding: 2vmin !important;
}

.ReactModal__Body--open {
  overflow: hidden;
}
