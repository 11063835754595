.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.searchButton {
  display: flex;
  justify-content: center;
  padding: 0 10px;
}

.clearButton {
  display: flex;
  justify-content: center;
  padding: 0 15px;
  position: absolute;
  right: 0;
}

.loupeIcon {
  width: 20px !important;
  height: 20px !important;
  fill: black !important;
  cursor: pointer !important;
}

.clearIcon {
  width: 14px !important;
  height: 14px !important;
  fill: black !important;
  cursor: pointer !important;
}

.input {
  display: flex;
  flex: 1;
  font-family: 'TeleNeoWeb';
  font-size: 18px;
  line-height: 18px;
  outline: none;
  border: none;
}
