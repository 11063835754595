@import '../../../resources/colors.module.scss';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow: hidden;

  @media (max-width: 1190px) {
    align-items: unset;
  }

  @media (max-width: 768px) {
    min-height: unset;
    height: auto;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 40px;
    margin-bottom: 50px;
    max-width: 1110px;

    @media (max-width: 400px) {
      margin: 0 20px;
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 40px;
      margin-bottom: 20px;
      height: 62px;
      min-width: 540px;

      font-size: 26px;
      font-weight: bold;
      color: $primary;
      text-transform: uppercase;
      border: solid 1px rgba(0, 0, 0, 0.4);
      border-radius: 4px;
      background: $white;
      transition: all 0.2s;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      @media (max-width: 620px) {
        min-width: unset;
        max-width: 540px;
        width: 100%;
      }

      @media (max-width: 480px) {
        height: 48px;
        font-size: 20px;
      }

      @media (max-width: 400px) {
        margin: 0 20px;
        margin-bottom: 20px;
      }
    }
  }
}
