@import '../../../resources/colors.module.scss';

.container {
  width: 100%;
  margin-top: 10px;
  background-color: $whiteBackground;

  .content {
    width: 100%;
    max-width: 1150px;
    padding: 0 40px;

    @media(max-width: 400px) {
      padding: 0 20px;
    }

    .description {
      font-size: 24px;
      line-height: 30px;
  
      @media(max-width: 1280px) {
        font-size: 24px;
      }
      @media(max-width: 960px) {
        font-size: 24px;
      }
      @media(max-width: 600px) {
        font-size: 22px;
      }
      @media(max-width: 400px) {
        font-size: 18px;
        line-height: 24px;
      }
    }
  
    .buttonsContainer {
      margin-top: 20px;
      .button {
        width: 46%;
        height: 36%;
        padding: 20px;
        margin-bottom: 10px;
        background-color: $white;
        cursor: pointer;
        border-radius: 2px;
        display: inline-block;
        box-shadow: -1px 3px 6px rgba(0, 0, 0, 0.16),
          3px 3px 6px rgba(0, 0, 0, 0.33);
  
        .icon {
          font-size: 16px;
          color: $lightGray;
        }
        .iconSelected {
          color: $white;
        }
  
        .text {
          display: grid;
          font-size: 20px;
          text-align: center;
          padding-top: 16px;
          color: $darkGrayText;
        }
  
        .textSelected {
          color: $white;
        }
      }
  
      .buttonSelected {
        background-color: $primary;
      }
    }
  
    .buttonNext {
      padding: 20px;
      margin-top: 16px;
      background-color: $primary;
      cursor: pointer;
      border-radius: 2px;
      .buttonNextText {
        color: $white;
        font-size: 22px;
        text-align: center;
        font-weight: bold;
      }
    }
  
    .progress {
      margin: 5vh 0;
      border-radius: 2px;
    }
  }
}

@media (min-width: 650px) {
  .container {
    display: flex;
    justify-content: center;
    padding: 20px 10px;
    .content {
      .buttonsContainer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 2vmin;
        grid-row-gap: 2vmin;
        grid-auto-rows: 1fr;
        margin-top: 4vmin;
        .button {
          width: auto;
          height: auto;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          padding: 1.5vmin;
          margin: 0 !important;
          border-radius: 0.5vmin;

          .icon {
            font-size: 2.75vmin;
          }
          .text {
            padding: 0;
            font-size: 3vmin;
            flex-grow: 1;
          }
        }
      }
      .buttonNext {
        margin: 0 auto;
        max-width: 500px;
        padding: 1.5vmin;
        margin-top: 3vmin;
        border-radius: 0.5vmin;
        .buttonNextText {
          font-size: 3.5vmin;
        }
      }
      .progress {
        margin-top: 5vmin;
      }
    }
  }
}
