@import '../../../../../../resources/colors.module.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
}

// HOT FIX
.icon {
  margin-top: 2px !important;
  width: 25px !important;
  height: 25px !important;
  fill: $black !important;

  &:hover {
    fill: $primary !important;
  }
}

.active {
  fill: $primary !important;
}

.counter {
  margin-left: 5px;
  font-size: 18px;
  font-family: 'TeleNeoWeb';
}
