@import '../../../../../resources/colors.module.scss';

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 120px;
  margin-right: 60px;
  height: 100px;

  @media (max-width: 768px) {
    margin: 30px 0;
  }

  @media (max-width: 450px) {
    height: 70px;
  }

  &::after {
    content: '';
    position: absolute;
    top: -120px;
    left: 50%;
    display: block;
    width: 2px;
    height: 130px;
    transform: translateX(-50%);
    background-color: white;

    @media (max-width: 768px) {
      top: unset;
      left: unset;
      right: -130px;
      width: 130px;
      height: 2px;
      transform: translate(0, -50%);
    }

    @media (max-width: 450px) {
      right: -80px;
      width: 80px;
    }
  }

  &::before {
    content: '';
    position: absolute;
    bottom: -120px;
    left: 50%;
    display: block;
    width: 2px;
    height: 130px;
    transform: translateX(-50%);
    background-color: white;

    @media (max-width: 768px) {
      bottom: unset;
      left: -130px;
      width: 130px;
      height: 2px;
      transform: translate(0, -50%);
    }

    @media (max-width: 450px) {
      left: -80px;
      width: 80px;
    }
  }

  .triangle {
    position: relative;
    margin: 0;
    height: 100%;
    width: auto;
    fill: $white;
    transform: rotateZ(90deg);

    @media (max-width: 768px) {
      transform: rotateZ(180deg);
    }
  }

  .counter {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    width: 90%;
    color: $primary;
    font-size: 32px;
    font-weight: 900;
    z-index: 1;

    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 10px;
    }

    @media (max-width: 450px) {
      font-size: 24px;
    }
  }
}
