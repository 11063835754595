@import '../../../../resources/colors.module.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 400px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 60px 0;
    height: auto;
  }

  .smallCup {
    width: auto;
    height: 160px;
    fill: $white;

    @media (max-width: 768px) {
      height: 110px;
    }
  }

  .bigCup {
    width: auto;
    height: 230px;
    fill: $white;

    @media (max-width: 768px) {
      height: 180px;
    }
  }

  .smallCounter {
    position: absolute;
    padding-bottom: 52px;
    color: $primary;
    font-size: 50px;
    font-weight: 900;

    @media (max-width: 768px) {
      padding-bottom: 36px;
      font-size: 36px;
    }
  }

  .bigCounter {
    position: absolute;
    padding-bottom: 74px;
    color: $primary;
    font-size: 70px;
    font-weight: 900;

    @media (max-width: 768px) {
      padding-bottom: 60px;
      font-size: 52px;
    }
  }

  // ANOMATIONS
  .block {
    position: relative;
    opacity: 0;
  }

  .firstAnimation {
    animation-name: block-animation;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
  }

  .secondAnimation {
    animation-name: block-animation;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
    animation-delay: 0.6s;
  }

  .thirdAnimation {
    animation-name: block-animation;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
    animation-delay: 1.2s;
  }

  @keyframes block-animation {
    0% {
      transform: translateY(30px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
}
