.content {
  background-color: white;
  width: 640px;
  padding: 48px;
  font-family: TeleNeoWeb;
  font-weight: 500;
  color: #383838;
  line-height: 24px;
  font-size: 18px;
}
.settingsContent {
  width: 956px;
  text-align: center;
  position: relative;
}
.title {
  font-size: 35px;
  margin-bottom: 18px;
}
.btnWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
  justify-content: flex-end;
}
.btnSettingsWrapper {
  justify-content: space-between
}
.btn {
  background-color: #e20074;
  line-height: 45px;
  height: 48px;
  width: 240px;
  color: white;
  text-align: center;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
}
.btn:hover {
  background-color: #cb0069;
}

.content span {
  text-decoration: underline;
  cursor: pointer;
}

.spanClose {
  color: #cb0069;
}

.linksWrapper {
  margin-top: 18px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #3d78a6;
}

.togglesWrapper {
  margin-top: 50px;
}

.closeSettings {
  position: absolute;
  top: 12px;
  right: 12px;
}
