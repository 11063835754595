@import '../../../resources/colors.module.scss';

.item {
  margin: 10px;
  display: flex;
  flex-direction: row;
  background: $white;
  box-shadow: none;
  border-bottom: 1px solid $gray;
  border-radius: 0;
  cursor: pointer;

  .img {
    margin-bottom: 1vmin;
    width: 140px;
    height: 140px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }

  .labelContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 140px;
    width: 210px;
    margin-left: 2vmin;

    .title {
      color: $gray;
      font-size: 20px;
    }
  }
}