@import '../../../resources/colors.module.scss';

.container {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 10px;
  background-color: white;
}

.slider {
  position: absolute;
  margin: 0 20%;
  width: 60%;
  border-radius: 6px;
  background-color: rgb(199, 199, 199);
  transition: all 0.2s;
}
