@import '../../../../resources/colors.module.scss';

$slide1-bg: url('../../../../resources/png/bg_horizontal.jpg');

@media (max-width: 768px) {
  $slide1-bg: url('../../../../resources/png/bg_vertikal.jpg');
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-top: 10px;
  width: 100%;

  .darkBackground {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
