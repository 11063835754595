@import '../../../resources/colors.module.scss';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  z-index: 1;

  .topInfo {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-top: 16px;
    margin-bottom: 10px;

    .name {
      margin-left: 10px;
      color: $white;
      font-weight: 700;
      font-size: 23px;
    }

    .largeName {
      margin-left: 10px;
      color: $white;
      font-weight: 700;
      font-size: 22px;
    }

    .rank {
      color: $white;
      font-weight: 700;
      font-size: 23px;
      margin-right: 2rem;
      width: 50px;
      text-align: center;
    }

    .largeRank {
      color: $white;
      font-weight: 700;
      font-size: 28px;
      width: 50px;
      text-align: center;
    }
  }

  .progressContainer {
    display: flex;
    align-items: center;
    height: 36px;
    width: 100%;

    .slider {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
        width: 64px;
        font-size: 26px;
      color: $white;
      border-radius: 18px;
      background-color: #b1b1b1;
      z-index: 1;
    }

    .filledLine {
      position: absolute;
      height: 14px;
      border-radius: 7px;
      background-color: #b1b1b1;
    }

    .progressLine {
      height: 14px;
      width: 100%;
      border-radius: 7px;
      background-color: #b1b1b1;
      opacity: 0.2;
    }
  }
}
