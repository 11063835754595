.container {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-left: 30px;
  height: 38px;
  border-radius: 4px;
  background-color: white;

  @media (max-width: 800px) {
    flex: none;
    margin-top: 10px;
    margin-left: 0;
  }
}
