@import '../../../../../resources/colors.module.scss';

.container {
  position: absolute;
  top: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  width: 100%;
  background-color: #252627;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  border-top: solid 2px white;

  @media (max-width: 960px) {
    padding-top: 0;
    width: 100%;
    border-top: none;
  }
}

.title {
  color: $white;
  font-family: 'TeleNeoWeb';
  font-weight: 800;
  font-size: 20px;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 960px) {
    flex-direction: column-reverse;
    align-items: center;
    padding: 0 40px;
    width: 100%;
  }

  @media (max-width: 400px) {
    padding: 0 15px;
  }
}
