@import '../../../../resources/colors.module.scss';

.container {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 70px;

  @media (max-width: 1024px) {
    padding-top: 0;
  }

  .description {
    font-size: 30px;
    line-height: 40px;
    color: $white;
    margin-top: 20px;
    margin-bottom: 26px;
    width: 100%;
    max-width: 100%;

    @media (max-width: 600px) {
      font-size: 24px;
      line-height: 32px;
    }

    @media (max-width: 480px) {
      margin-top: 0;
      font-size: 22px;
      line-height: 28px;
    }
  }

  .optionContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;
    max-width: 100%;
    color: white;

    &:nth-child(even) {
      margin-bottom: 22px;

      @media (max-width: 480px) {
        margin-bottom: 14px;
      }
    }

    .square {
      width: 35px;
      height: 35px;
      margin-right: 15px;
      background-color: $primary;
      box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.3);

      @media (max-width: 480px) {
        width: 24px;
        height: 24px;
      }
    }

    .white {
      background-color: $white;
    }

    .optionName {
      font-size: 28px;
      color: $white;

      &::first-letter {
        text-transform: capitalize;
      }

      @media (max-width: 480px) {
        font-size: 20px;
      }
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    height: 62px;
    width: 100%;
    max-width: 100%;
    font-size: 26px;
    font-weight: bold;
    color: $primary;
    text-transform: uppercase;
    border: solid 1px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    background: $white;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    @media (max-width: 1024px) {
      margin-bottom: 20px;
    }

    @media (max-width: 768px) {
      margin-top: 40px;
    }

    @media (max-width: 480px) {
      margin-top: 30px;
      height: 48px;
      font-size: 20px;
    }
  }
}
