@import '../../../resources/colors.module.scss';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-image: url('../../../resources/png/bg_horizontal_2.jpg');
  background-size: cover;
  background-attachment: fixed;
  overflow: auto;
}

@media (max-width: 768px) {
  .container {
    background-image: url('../../../resources/png/bg_vertikal_2.jpg');
  }
}


.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-y: scroll;
  overflow-x: hidden;
}
