@import '../../../../../../resources/colors.module.scss';

.container {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: 30px;
  height: 250px;
  width: 100%;
  max-width: 350px;
  overflow: hidden;

  @media (max-width: 960px) {
    margin-top: 10px;
    margin-left: 0;
  }
}

.image {
  width: 100%;
}

.video {
  width: 100%;
}
