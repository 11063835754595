@import '../../../../../../resources/colors.module.scss';

.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 2;
}

.title {
  margin-top: 10px;
  margin-bottom: 5px;
  color: $primary;
  font-size: 18px;
  font-family: 'TeleNeoWeb';
  font-weight: 700;
  cursor: default;
}

.description {
  width: 200px;
  color: $darkGrayText;
  font-family: 'TeleNeoWeb';
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
}

.buttons {
  margin-top: 20px;
}

.button {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 41px;
  width: 177px;
  border-radius: 4px;
  border: solid 1px #b2b2b2;
  background-color: $whiteBackground;
  cursor: pointer;
}

.buttonGap {
  margin-bottom: 10px;
}

.buttonText {
  color: #383838;
  font-size: 18px;
  font-family: 'TeleNeoWeb';
  font-weight: 700;
  text-transform: uppercase;
}
