@import '../../../../../resources/colors.module.scss';

.container {
  display: none;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  top: 100px;
  width: 100vw;
  height: 56px;
  box-shadow: 0 10px 10px -4px rgba(13, 35, 67, 0.25);
  background-color: $white;
  z-index: 100;

  @media (max-width: 1280px) {
    display: flex;
  }

  @media (max-width: 400px) {
    // top: 80px; WIP
  }
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 40px;
  max-width: 1110px;
  height: 100%;
  width: 100%;

  @media (max-width: 400px) {
    // height: 80px; WIP
    margin: 0 20px;
  }
}

.buttonsContainer {
  display: flex;
  align-items: center;
  height: 100%;
  // overflow: hidden;

  .button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 60px;
    padding: 0 14px;
    height: 100%;
    transition: background-color 0.2s;

    &:hover {
      background-color: $primary;
      .icon {
        fill: $white;
      }
    }

    @media (max-width: 768px) {
      padding: 0 10px;
    }

    .icon {
      fill: rgb(57, 57, 57);
      height: 32px;
      width: auto;
    }

    .iconLogo {
      fill: rgb(57, 57, 57);
      width: 70px;
      height: auto;
      margin-top: 3px;
    }

    .appStoreIcon {
      margin-bottom: 2px;
      height: 30px;
      width: auto;
      fill: #000;
    }

    .playStoreIcon {
      margin-bottom: 2px;
      height: 30px;
      width: auto;

      // HOTFIX
      margin-bottom: 0px;
    }
  }

  .leftButton {
    margin-left: -14px;

    @media (max-width: 600px) {
      margin-left: -12px;
    }
  }

  .rightButton {
    margin-right: -14px;

    @media (max-width: 600px) {
      margin-right: -10px;
    }
  }
}

.counter {
  position: absolute;
  top: 6px;
  right: 8px;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border: solid 2px white;
  border-radius: 11px;
  background-color: $primary;
}

.counterText {
  color: $white;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
