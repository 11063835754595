@import '../../../../../resources/colors.module.scss';

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: $primary;

  .innerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 40px;
    max-width: 1110px;
    height: 100px;
    width: 100%;

    @media (max-width: 768px) {
      height: 60px;
    }
    
    @media (max-width: 400px) {
      margin: 0 20px;
    }

    .logo {
      height: 40px;
      width: auto;
      fill: $white;

      @media (max-width: 600px) {
        height: 30px;
      }
    }

    .textLogo {
      height: 15px;

      @media (max-width: 600px) {
        height: 10px;
      }
    }
  }
}
