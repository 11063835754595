@import '../../../resources/colors.module.scss';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: left, right top, center;
  background-size: auto, auto, cover;
  // background-color: rgba(0, 0, 0, 0.35);

  .samsungLogo {
    margin: auto 0;
    width: 450px;
    height: auto;
    color: white;

    @media (max-width: 768px) {
      width: 50%;
    }
  }

  .buttonsContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    // margin-top: 12%;

    .innerButtonsContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0 40px;
      margin-bottom: 30px;
      max-width: 1110px;
      width: 100%;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      @media (max-width: 640px) {
        // height: 80px; WIP
        margin: 0 20px;
        margin-bottom: 50px;
      }
      .mobileListContainer {
        display: none;
        @media (max-width: 768px) {
          display: block;
        }
      }

      .button {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        flex: 1;
        height: 320px;
        background-color: $primary;
        border-radius: 4px;
        cursor: pointer;
        margin: 0 24px;
        // &:nth-child(even) {
        //   margin: 0 24px;
        // }

        &:hover {
          opacity: 0.6;
        }

        &:active {
          opacity: 0.5;
        }

        @media (max-width: 1100px), (max-height: 720px) {
          flex: 1;
          height: 280px;
        }

        @media (max-width: 768px) {
          flex: unset;
          flex-direction: row;
          justify-content: flex-start;
          height: 100px;

          // &:nth-child(even) {
            margin: 14px 0;
          // }
        }

        @media (max-width: 420px) {
          height: 60px;
        }

        .icon {
          margin: 0;
          width: 280px;
          height: 120px;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: contain;

          @media (max-width: 1100px), (max-height: 720px) {
            margin: 0;
            width: 200px;
            height: 110px;
          }

          @media (max-width: 860px) {
            margin: 0;
            width: 180px;
            height: 100px;
          }

          @media (max-width: 768px) {
            margin: 0 40px;
            width: 100px;
            height: 60px;
          }

          @media (max-width: 600px) {
            margin: 0 20px;
            width: 90px;
            height: 50px;
          }

          @media (max-width: 420px) {
            margin: 0 20px;
            width: 60px;
            height: 40px;
          }
        }

        .titleContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 90px;
          text-transform: uppercase;

          @media (max-width: 768px) {
            flex-grow: 1;
          }

          .title {
            color: $white;
            font-weight: bold;
            font-size: 34px;
            text-align: center;

            @media (max-width: 1100px) {
              font-size: 28px;
            }

            @media (max-width: 768px) {
              height: auto;
              font-size: 26px;
            }

            @media (max-width: 600px) {
              height: auto;
              font-size: 24px;
            }

            @media (max-width: 420px) {
              height: auto;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

.desktopListContainer {
  display: block;
  @media (max-width: 768px) {
    display: none !important;
  }
}
