@import '../../../../resources/colors.module.scss';

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  padding: 40px 40px;
  width: 100%;
  background-color: $darkGray;
  z-index: 1;
  @media (max-width: 800px) {
    flex-direction: column;
    padding: 20px 40px;
  }

  @media (max-width: 400px) {
    padding: 20px 15px;
  }
}

.innerContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1110px;

  @media (max-width: 800px) {
    flex-direction: column;
  }
}

.button {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  max-width: 350px;
  flex-basis: 350px;
  background-color: $primary;
  border-radius: 4px;
  cursor: pointer;

  @media (max-width: 800px) {
    max-width: 100%;
    flex-basis: auto;
  }
}

.buttonIcon {
  position: absolute;
  left: 10px;
  width: 32px;
  height: 32px;
  fill: $whiteBackground;
}

.buttonText {
  color: $whiteBackground;
  text-transform: uppercase;
  font-size: 18px;
  font-family: 'TeleNeoWeb';
  font-weight: 700;
}

.popup{
  position: absolute;
  top: 100%;
  width: 100%;
  height: 200px;
  background-color: aqua;
}
