@import '../../../../resources/colors.module.scss';

.container {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: default;
  z-index: 2;

  .gauge {
    margin-left: -44px;
    width: 680px;
    height: 680px;

    @media (max-width: 1024px) {
      margin-left: 0px;
    }

    @media (max-width: 768px) {
      width: 500px;
      height: 500px;
    }

    @media (max-width: 480px) {
      margin-left: 0px;
      width: 380px;
      height: 380px;
    }
  }

  .gaugeContent {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    margin-left: -44px;
    width: 100%;
    height: 100%;

    @media (max-width: 1024px) {
      margin-left: 0px;
    }

    .scoreTitle {
      margin-top: 16px;
      font-size: 34px;
      color: $white;

      @media (max-width: 768px) {
        margin-left: 0px;
        margin-top: 0px;
        font-size: 24px;
      }

      @media (max-width: 480px) {
        margin-top: 4px;
        font-size: 18px;
      }
    }

    .scoreValue {
      font-size: 34px;
      color: $white;

      @media (max-width: 768px) {
        font-size: 24px;
      }

      @media (max-width: 480px) {
        font-size: 18px;
      }
    }
  }
}
