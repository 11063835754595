@import '../../../../resources/colors.module.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  left: 0;
  padding: 0 20px;
  width: 100%;
  max-width: 1150px;
  background-color: $white;

  @media(max-width: 1180px) {
    padding: 0 40px;
  }
  @media(max-width: 400px) {
    padding: 0 20px;
  }

  .list {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10vh;

    .item {
      display: flex;
      align-items: center;
      text-decoration: none;
      padding-top: 3vh;
      padding-bottom: 3vh;
      cursor: pointer;
      border-bottom: 2px solid $boarderGray;

      .image {
        margin-right: 4vw;
        background-position: 50% 50%;
        background-size: cover;
      }
      .label {
        overflow-wrap: break-word;
        color: $lightGrayText;
        cursor: pointer;
        max-width: 50%;
        font-size: 26px;
        line-height: 26px;

        @media(max-width: 960px) {
          font-size: 24px;
          line-height: 24px;
        }
        @media(max-width: 600px) {
          font-size: 22px;
        }
        @media(max-width: 400px) {
          font-size: 20px;
        }
      }
    }
  }
}

// media queryes
@media (orientation: landscape) {
  .list {
    flex-direction: row;
    flex-wrap: wrap;

    .item {
      width: 45%;
      .image {
        width: 6vw;
        height: 6vw;
      }

      .label {
        font-size: 2.5vmin;
        line-height: 3vmin;
      }
    }
  }
}

@media (orientation: portrait) {
  .list {
    flex-direction: column;

    .item {
      .image {
        width: 10vw;
        height: 10vw;
      }

      .label {
        font-size: 4.5vmin;
        line-height: 4.5vmin;
      }
    }
  }
}
